import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuizContext } from "../context/QuizContext";
import "../assets/css/style.css";
import "../assets/css/home.css";
import "../assets/css/quiz.css";
import "../assets/css/result.css";

function Home() {
  const { user, setUser } = useQuizContext();
  const [allValid, setAllValid] = useState(false);

  const isNameValid = user?.name !== "";
  const isEmailValid = /\S+@\S+\.\S+/.test(user?.email);
  const isPhoneValid = user?.number !== "";

  function handleInput(e, key) {
    setUser({
      ...user,
      [key]: e.target.value,
    });
  }
  useEffect(() => {
    const inputsAreValid = isNameValid && isEmailValid && isPhoneValid;
    setAllValid(inputsAreValid);
  }, [isNameValid, isEmailValid, isPhoneValid]);

  return (
    <div className="app-container">
      <div className="quiz-box">
        <div className="home">
          <div className="intro-box">
            <div className="intro-texts">
              <h1 className="intro-title">
                London School Skill Assessment Test
              </h1>
              <p className="intro-description">
                Please write your details below to take the test
              </p>
            </div>
          </div>

          <form>
            <input
              className="level-input"
              name="name"
              type="text"
              required
              value={user?.name}
              onChange={(e) => handleInput(e, "name")}
              placeholder="Name and Surname"
            />
            <input
              className="level-input"
              name="number"
              type="text"
              required
              value={user?.number}
              onChange={(e) => handleInput(e, "number")}
              placeholder="Phone Number"
            />
            <input
              className="level-input"
              name="email"
              type="email"
              required
              value={user?.email}
              onChange={(e) => handleInput(e, "email")}
              placeholder="Email"
            />
            <Link className={allValid ? "level-link" : "disabled"} to="/quiz">
              Start Quiz
            </Link>
          </form>
          <p className="my-2">
            *Note that there is no turning back to previous question
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
