import React, { createContext, useContext, useState } from "react";

const QuizContext = createContext();

function Provider({ children }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [user,setUser] = useState({
    name:'',
    number:'',
    email:'',

  })
  const questions = [
    
      {
        id: 1,
        type:'1-5',
        general:'Where can you see these notices?',
        question: "You can look, but don’t touch the pictures.",
        answers: [
          { answer: "in an office", trueAnswer: false },
          { answer: "in a cinema", trueAnswer: false },
          { answer: "in a museum", trueAnswer: true }
        ],
      } ,
      {
        id: 2,
        type:'1-5',
        general:'Where can you see these notices?',
        question: "Please give the right money to the driver.",
        answers: [
          { answer: "in a bank", trueAnswer: false },
          { answer: "on a bus", trueAnswer: true },
          { answer: "in a cinema", trueAnswer: false },
        ],
      },
      {
        id: 3,
        type:'1-5',
        general:'Where can you see these notices?',
        question: "No Parking Please",
        answers: [
          { answer: "in a street", trueAnswer: true },
          { answer: "on a book", trueAnswer: false },
          { answer: "on a table", trueAnswer: false },
        ]
      },
      {
        id: 4,
        type:'1-5',
        general:'Where can you see these notices?',
        question: "CROSS BRIDGE FOR TRAINS TO EDINBURGH",
        answers: [
          { answer: "in a bank", trueAnswer: false },
          { answer: "in a garage", trueAnswer: false },
          { answer: "in a station", trueAnswer: true }
        ],
      },
      {
        id: 5,
        type:'1-5',
        general:'Where can you see these notices?',
        question: "KEEP IN A COLD PLACE",
        answers: [
          { answer: "on clothes", trueAnswer: false },
          { answer: "on furniture", trueAnswer: false },
          { answer: "on food", trueAnswer: true }
        ],
      },
      {
        id: 6,
        type:'6-10',
        general:'There are millions of stars in the sky. If you look (6) .................. the sky on a clear night, it is possible to see about 3000 stars. They look small, but they are really (7) .................. big hot balls of burning gas. Some of them are huge, but others are much smaller, like our planet Earth. The biggest stars arevery bright, but they only live for a short time. Every day new stars (8) .................. born and old stars die. All the stars are very far away. The light from the nearest star takes more (9) .................. four years to reach Earth. Hundreds of years ago, people (10) .................. stars, like the North star, to know which direction to travel in. Today you can still see that star.',
        question: "(6)",
        answers: [
          { answer: "at", trueAnswer: true },
          { answer: "up", trueAnswer: false },
          { answer: "on", trueAnswer: false },
        ],
      },
      {
        id: 7,
        type:'6-10',
        general:'There are millions of stars in the sky. If you look (6) .................. the sky on a clear night, it is possible to see about 3000 stars. They look small, but they are really (7) .................. big hot balls of burning gas. Some of them are huge, but others are much smaller, like our planet Earth. The biggest stars arevery bright, but they only live for a short time. Every day new stars (8) .................. born and old stars die. All the stars are very far away. The light from the nearest star takes more (9) .................. four years to reach Earth. Hundreds of years ago, people (10) .................. stars, like the North star, to know which direction to travel in. Today you can still see that star.',
        question: "(7)",
        answers: [
          { answer: "very", trueAnswer: true },
          { answer: "too", trueAnswer: false },
          { answer: "much", trueAnswer: false },
        ],
      },
      {
        id: 8,
        type:'6-10',
        general:'There are millions of stars in the sky. If you look (6) .................. the sky on a clear night, it is possible to see about 3000 stars. They look small, but they are really (7) .................. big hot balls of burning gas. Some of them are huge, but others are much smaller, like our planet Earth. The biggest stars arevery bright, but they only live for a short time. Every day new stars (8) .................. born and old stars die. All the stars are very far away. The light from the nearest star takes more (9) .................. four years to reach Earth. Hundreds of years ago, people (10) .................. stars, like the North star, to know which direction to travel in. Today you can still see that star.',
        question: "(8)",
        answers: [
          { answer: "is", trueAnswer: false },
          { answer: "be", trueAnswer: false },
          { answer: "are", trueAnswer: true },
        ],
      },
      {
        id: 9,
        type:'6-10',
        general:'There are millions of stars in the sky. If you look (6) .................. the sky on a clear night, it is possible to see about 3000 stars. They look small, but they are really (7) .................. big hot balls of burning gas. Some of them are huge, but others are much smaller, like our planet Earth. The biggest stars arevery bright, but they only live for a short time. Every day new stars (8) .................. born and old stars die. All the stars are very far away. The light from the nearest star takes more (9) .................. four years to reach Earth. Hundreds of years ago, people (10) .................. stars, like the North star, to know which direction to travel in. Today you can still see that star.',
        question: "(9)",
        answers: [
          { answer: "that", trueAnswer: false },
          { answer: "of", trueAnswer: false },
          { answer: "than", trueAnswer: true },
        ],
      },
      {
        id: 10,
        type:'6-10',
        general:'There are millions of stars in the sky. If you look (6) .................. the sky on a clear night, it is possible to see about 3000 stars. They look small, but they are really (7) .................. big hot balls of burning gas. Some of them are huge, but others are much smaller, like our planet Earth. The biggest stars arevery bright, but they only live for a short time. Every day new stars (8) .................. born and old stars die. All the stars are very far away. The light from the nearest star takes more (9) .................. four years to reach Earth. Hundreds of years ago, people (10) .................. stars, like the North star, to know which direction to travel in. Today you can still see that star.',
        question: "(10)",
        answers: [
          { answer: "use", trueAnswer: false },
          { answer: "used", trueAnswer: true },
          { answer: "using", trueAnswer: false },
        ],
      },
      {
        id: 11,
        type:'11-15',
        general:'Older Britons are the worst in Europe when it comes to keeping their teeth. But British youngsters (11) .................. more to smile about because (12) .................. teeth are among the best. Almost 80% of Britons over 65 have lost all or some (13) .................. their teeth according to a World Health Organisation survey. Eating too (14) .................. sugar is part of the problem. Among (15) .................. , 12-year olds have on average only three missing, decayed or filled teeth.',
        question: "(11)",
        answers: [
          { answer: "getting", trueAnswer: false },
          { answer: "got", trueAnswer: false },
          { answer: "have", trueAnswer: true },
          { answer: "having", trueAnswer: false },
        ],
      },
      {
        id: 12,
        type:'11-15',
        general:'Older Britons are the worst in Europe when it comes to keeping their teeth. But British youngsters (11) .................. more to smile about because (12) .................. teeth are among the best. Almost 80% of Britons over 65 have lost all or some (13) .................. their teeth according to a World Health Organisation survey. Eating too (14) .................. sugar is part of the problem. Among (15) .................. , 12-year olds have on average only three missing, decayed or filled teeth.',
        question: "(12)",
        answers: [
          { answer: "their", trueAnswer: true },
          { answer: "his", trueAnswer: false },
          { answer: "them", trueAnswer: false },
          { answer: "theirs", trueAnswer: false },
        ],
      },
      {
        id: 13,
        type:'11-15',
        general:'Older Britons are the worst in Europe when it comes to keeping their teeth. But British youngsters (11) .................. more to smile about because (12) .................. teeth are among the best. Almost 80% of Britons over 65 have lost all or some (13) .................. their teeth according to a World Health Organisation survey. Eating too (14) .................. sugar is part of the problem. Among (15) .................. , 12-year olds have on average only three missing, decayed or filled teeth.',
        question: "(13)",
        answers: [
          { answer: "from", trueAnswer: false },
          { answer: "of", trueAnswer: true },
          { answer: "among", trueAnswer: false },
          { answer: "between", trueAnswer: false },
        ],
      },
      {
        id: 14,
        type:'11-15',
        general:'Older Britons are the worst in Europe when it comes to keeping their teeth. But British youngsters (11) .................. more to smile about because (12) .................. teeth are among the best. Almost 80% of Britons over 65 have lost all or some (13) .................. their teeth according to a World Health Organisation survey. Eating too (14) .................. sugar is part of the problem. Among (15) .................. , 12-year olds have on average only three missing, decayed or filled teeth.',
        question: "(14)",
        answers: [
          { answer: "much", trueAnswer: true },
          { answer: "lot", trueAnswer: false },
          { answer: "many", trueAnswer: false },
          { answer: "deal", trueAnswer: false },
        ],
      },
      {
        id: 15,
        type:'11-15',
        general:'Older Britons are the worst in Europe when it comes to keeping their teeth. But British youngsters (11) .................. more to smile about because (12) .................. teeth are among the best. Almost 80% of Britons over 65 have lost all or some (13) .................. their teeth according to a World Health Organisation survey. Eating too (14) .................. sugar is part of the problem. Among (15) .................. , 12-year olds have on average only three missing, decayed or filled teeth.',
        question: "(15)",
        answers: [
          { answer: "person", trueAnswer: false },
          { answer: "people", trueAnswer: false },
          { answer: "children", trueAnswer: true },
          { answer: "family", trueAnswer: false },
        ],
      },
      {
        id: 16,
        type:'16-20',
        general:'On August 3, 1492, Christopher Columbus set sail from Spain to find a new route to India,China and Japan. At this time most people thought you would fall off the edge of the world if you sailed too far. Yet sailors such as Columbus had seen how a ship appeared to get lower and lower on the horizon as it sailed away. For Columbus this (16) ................. that the world was round. He (17) ................. to his men about the distance travelled each day. He did not want them to think that he did not (18) ................. exactly where they were going. (19) ................. , on October 12, 1492, Columbus and his men landed on a small island he named San Salvador. Columbus believed he was in Asia, (20) ................. he was actually in the Caribbean.',
        question: "(16)",
        answers: [
          { answer: "made", trueAnswer: false },
          { answer: "pointed", trueAnswer: false },
          { answer: "was", trueAnswer: false },
          { answer: "proved", trueAnswer: true },
        ],
      },
      {
        id: 17,
        type:'16-20',
        general:'On August 3, 1492, Christopher Columbus set sail from Spain to find a new route to India,China and Japan. At this time most people thought you would fall off the edge of the world if you sailed too far. Yet sailors such as Columbus had seen how a ship appeared to get lower and lower on the horizon as it sailed away. For Columbus this (16) ................. that the world was round. He (17) ................. to his men about the distance travelled each day. He did not want them to think that he did not (18) ................. exactly where they were going. (19) ................. , on October 12, 1492, Columbus and his men landed on a small island he named San Salvador. Columbus believed he was in Asia, (20) ................. he was actually in the Caribbean.',
        question: "(17)",
        answers: [
          { answer: "lied", trueAnswer: true },
          { answer: "told", trueAnswer: false },
          { answer: "cheated", trueAnswer: false },
          { answer: "asked", trueAnswer: false },
        ],
      },
      {
        id: 18,
        type:'16-20',
        general:'On August 3, 1492, Christopher Columbus set sail from Spain to find a new route to India,China and Japan. At this time most people thought you would fall off the edge of the world if you sailed too far. Yet sailors such as Columbus had seen how a ship appeared to get lower and lower on the horizon as it sailed away. For Columbus this (16) ................. that the world was round. He (17) ................. to his men about the distance travelled each day. He did not want them to think that he did not (18) ................. exactly where they were going. (19) ................. , on October 12, 1492, Columbus and his men landed on a small island he named San Salvador. Columbus believed he was in Asia, (20) ................. he was actually in the Caribbean.',
        question: "(18)",
        answers: [
          { answer: "find", trueAnswer: false },
          { answer: "know", trueAnswer: true },
          { answer: "think", trueAnswer: false },
          { answer: "expect", trueAnswer: false },
        ],
      },
      {
        id: 19,
        type:'16-20',
        general:'On August 3, 1492, Christopher Columbus set sail from Spain to find a new route to India,China and Japan. At this time most people thought you would fall off the edge of the world if you sailed too far. Yet sailors such as Columbus had seen how a ship appeared to get lower and lower on the horizon as it sailed away. For Columbus this (16) ................. that the world was round. He (17) ................. to his men about the distance travelled each day. He did not want them to think that he did not (18) ................. exactly where they were going. (19) ................. , on October 12, 1492, Columbus and his men landed on a small island he named San Salvador. Columbus believed he was in Asia, (20) ................. he was actually in the Caribbean.',
        question: "(19)",
        answers: [
          { answer: "Next", trueAnswer: false },
          { answer: "Secondly", trueAnswer: false },
          { answer: "Finally", trueAnswer: true },
          { answer: "Once", trueAnswer: false },
        ],
      },
      {
        id: 20,
        type:'16-20',
        general:'On August 3, 1492, Christopher Columbus set sail from Spain to find a new route to India,China and Japan. At this time most people thought you would fall off the edge of the world if you sailed too far. Yet sailors such as Columbus had seen how a ship appeared to get lower and lower on the horizon as it sailed away. For Columbus this (16) ................. that the world was round. He (17) ................. to his men about the distance travelled each day. He did not want them to think that he did not (18) ................. exactly where they were going. (19) ................. , on October 12, 1492, Columbus and his men landed on a small island he named San Salvador. Columbus believed he was in Asia, (20) ................. he was actually in the Caribbean.',
        question: "(20)",
        answers: [
          { answer: "as", trueAnswer: false },
          { answer: "but", trueAnswer: true },
          { answer: "because", trueAnswer: false },
          { answer: "if", trueAnswer: false },
        ],
      },
      {
        id: 21,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "The children won’t go to sleep .................... we leave a light on outside their bedroom.",
        answers: [
          { answer: "except", trueAnswer: false },
          { answer: "otherwise", trueAnswer: false },
          { answer: "unless", trueAnswer: true },
          { answer: "but", trueAnswer: false },
        ],
      },
      {
        id: 22,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "I’ll give you my spare keys in case you .................... home before me.",
        answers: [
          { answer: "would get", trueAnswer: false },
          { answer: "got", trueAnswer: false },
          { answer: "will get", trueAnswer: false },
          { answer: "get", trueAnswer: true },
        ],
      },
      {
        id: 23,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "My holiday in Paris gave me a great .................... to improve my French accent.",
        answers: [
          { answer: "occasion", trueAnswer: false },
          { answer: "chance", trueAnswer: true },
          { answer: "hope", trueAnswer: false },
          { answer: "possibility", trueAnswer: false },
        ],
      },
      {
        id: 24,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "The singer ended the concert .................... her most popular song",
        answers: [
          { answer: "by", trueAnswer: false },
          { answer: "with", trueAnswer: true },
          { answer: "in", trueAnswer: false },
          { answer: "as", trueAnswer: false },
        ],
      },
      {
        id: 25,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "Because it had not rained for several months, there was a ...................... of water.",
        answers: [
          { answer: "shortage", trueAnswer: true },
          { answer: "drop", trueAnswer: false },
          { answer: "scarce", trueAnswer: false },
          { answer: "waste", trueAnswer: false },
        ],
      },
      {
        id: 26,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "I’ve always .................... you as my best friend.",
        answers: [
          { answer: "regarded", trueAnswer: true },
          { answer: "thought", trueAnswer: false },
          { answer: "meant", trueAnswer: false },
          { answer: "supposed", trueAnswer: false },
        ],
      },
      {
        id: 27,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "She came to live here .................... a month ago.",
        answers: [
          { answer: "quite", trueAnswer: false },
          { answer: "beyond", trueAnswer: false },
          { answer: "already", trueAnswer: false },
          { answer: "almost", trueAnswer: true },
        ],
      },
      {
        id: 28,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "Don’t make such a ....................! The dentist is only going to look at your teeth.",
        answers: [
          { answer: "fuss", trueAnswer: true },
          { answer: "trouble", trueAnswer: false },
          { answer: "worry", trueAnswer: false },
          { answer: "reaction", trueAnswer: false },
        ],
      },
      {
        id: 29,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "He spent a long time looking for a tie which .................... with his new shirt.What is the meaning of the word 'elated'?",
        answers: [
          { answer: "fixed", trueAnswer: false },
          { answer: "made", trueAnswer: false },
          { answer: "went", trueAnswer: true },
          { answer: "wore", trueAnswer: false },
        ],
      },
      {
        id: 30,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "Fortunately, .................... from a bump on the head, she suffered no serious injuries from her fall.",
        answers: [
          { answer: "other", trueAnswer: false },
          { answer: "except", trueAnswer: false },
          { answer: "besides", trueAnswer: false },
          { answer: "apart", trueAnswer: true },
        ],
      },
      {
        id: 31,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "She had changed so much that .................... anyone recognised her",
        answers: [
          { answer: "almost", trueAnswer: false },
          { answer: "hardly", trueAnswer: true },
          { answer: "not", trueAnswer: false },
          { answer: "nearly", trueAnswer: false },
        ],
      },
      {
        id: 32,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: ".................... teaching English, she also writes children’s books",
        answers: [
          { answer: "Moreover", trueAnswer: false },
          { answer: "As well as", trueAnswer: true },
          { answer: "In addition", trueAnswer: false },
          { answer: "Apart", trueAnswer: false },
        ],
      },
      {
        id: 33,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "It was clear that the young couple were ..................... of taking charge of the restaurant.",
        answers: [
          { answer: "responsible", trueAnswer: false },
          { answer: "reliable", trueAnswer: false },
          { answer: "capable", trueAnswer: true },
          { answer: "able", trueAnswer: false },
        ],
      },
      {
        id: 34,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "The book .................... of ten chapters, each one covering a different topic.",
        answers: [
          { answer: "comprises", trueAnswer: false },
          { answer: "includes", trueAnswer: false },
          { answer: "consists", trueAnswer: true },
          { answer: "contains", trueAnswer: false },
        ],
      },
      {
        id: 35,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "Mary was disappointed with her new shirt as the colour .................... very quickly.",
        answers: [
          { answer: "bleached", trueAnswer: false },
          { answer: "died", trueAnswer: false },
          { answer: "vanished", trueAnswer: false },
          { answer: "faded", trueAnswer: true },
        ],
      },
      {
        id: 36,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "National leaders from all over the world are expected to attend the .................... meeting.",
        answers: [
          { answer: "peak", trueAnswer: false },
          { answer: "summit", trueAnswer: true },
          { answer: "top", trueAnswer: false },
          { answer: "apex", trueAnswer: false },
        ],
      },
      {
        id: 37,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "Jane remained calm when she won the lottery and .................... about her business as if nothing had happened.",
        answers: [
          { answer: "came", trueAnswer: false },
          { answer: "brought", trueAnswer: false },
          { answer: "went", trueAnswer: true },
          { answer: "moved", trueAnswer: false },
        ],
      },
      {
        id: 38,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "I suggest we ..................... outside the stadium tomorrow at 8.30.",
        answers: [
          { answer: "meeting", trueAnswer: false },
          { answer: "meet", trueAnswer: true },
          { answer: "met", trueAnswer: false },
          { answer: "will meet", trueAnswer: false },
        ],
      },
      {
        id: 39,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "My remarks were ..................... as a joke, but she was offended by them",
        answers: [
          { answer: "pretended", trueAnswer: false },
          { answer: "thought", trueAnswer: false },
          { answer: "meant", trueAnswer: true },
          { answer: "supposed", trueAnswer: false },
        ],
      },
      {
        id: 40,
        type:'21-40',
        general:'In this section you must choose the word or phrase which best completes each sentence.',
        question: "You ought to take up swimming for the ..................... of your health",
        answers: [
          { answer: "concern", trueAnswer: false },
          { answer: "relief", trueAnswer: false },
          { answer: "sake", trueAnswer: true },
          { answer: "cause", trueAnswer: false },
        ],
      } 
    ];

  const sharedValuesAndMethods = {
    user,
    setUser,
    questions,
    currentQuestion,
    setCurrentQuestion,
  };

  return (
    <QuizContext.Provider value={sharedValuesAndMethods}>
      {children}
    </QuizContext.Provider>
  );
}

const useQuizContext = () => useContext(QuizContext);
export { Provider, useQuizContext };

export default QuizContext;
