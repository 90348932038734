import Home from './components/Home';

import { Route } from 'react-router-dom';
import { Routes  } from 'react-router-dom';
import Layout from './components/Layout';
import Quiz from './components/Quiz';
import Result from './components/Result';
function App() {
  


  return (
      <Routes>
        <Route path='/' element={<Layout />}/>
        <Route path='/register' element={<Home />}/>
        <Route path='/quiz' element={<Quiz />}/>
        <Route path='/result' element={<Result />}/>
      </Routes>
  );
}

export default App;
