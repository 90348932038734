import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import {useQuizContext} from "../context/QuizContext";

import '../assets/css/style.css'
import '../assets/css/quiz.css'
import '../assets/css/home.css'
import '../assets/css/result.css'

function Quiz() {
  const navigate = useNavigate();
  const { user,questions, currentQuestion, setCurrentQuestion } = useQuizContext();
 

  const [isNextButton, setIsNextButton] = useState(false);
  const [isResultButton, setIsResultButton] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isResult, setIsResult] = useState(false);


  const selectAnswer = (index) => {
    if (currentQuestion === questions.length - 1) {
      setIsNextButton(false);
      setIsResultButton(true);
    } else {
      setIsNextButton(true);
    }
    setSelectedIndex(index);
  };

  const nextQuestion = (index) => {
    if (currentQuestion >= questions.length - 1) {
      addAnswer(index);
      setCurrentQuestion(0);
      setIsResult(true);
    } else {
      setIsNextButton(false);
      addAnswer(index);
      setCurrentQuestion(currentQuestion + 1);
      setSelectedIndex();
    }
  };

  const addAnswer = (index) => {
    const selectedAnswer =
      index !== null
        ? questions[currentQuestion].answers[index]
        : {
            answer: "Süre Bitti",
            trueAnswer: false,
          };
    const newAnswers = [...selectedAnswers, selectedAnswer];
    setSelectedAnswers(newAnswers);
  };

  

  return isResult ? (
    navigate("/result", {
      state: {
        answers: selectedAnswers,
        questions: questions,
        user:user
      },
    })
  ) : (
    <div className="app-container">
      <div className="quiz-box">
      <div className="progress-box">
        <div className="progress-top">
          <div className="progress-texts">
            <p>Questions {questions[currentQuestion].type}</p>
            <h3>{questions[currentQuestion].general}</h3>
          </div>
        </div>
      </div>
      <div className="question-box">
        <div className="question-text">
          <h2 className="question-title">Question: {currentQuestion + 1}</h2>
          <h1 className="question">
            {questions[currentQuestion].question}
          </h1>
        </div>
        
      </div>

      <div className="answers-boxes">
        {questions[currentQuestion].answers.map((answer, index) => {
          return (
            <label
              onClick={() => selectAnswer(index)}
              key={index}
              htmlFor={index}
              className={
                selectedIndex === index
                  ? "answer-label selected"
                  : "answer-label"
              }
            >
              {answer.answer}
              <input type="radio" name="answer" id={index} />
            </label>
          );
        })}
      </div>

      {isNextButton ? (
        <div className="next">
          <button
            onClick={() => nextQuestion(selectedIndex)}
            type="button"
            className="next-btn"
          >
            Next Question
            <div className="icon">
              <i className="bi bi-arrow-right"></i>
            </div>
          </button>
        </div>
      ) : null}

      {isResultButton ? (
        <div className="next">
          <button
            onClick={() => nextQuestion(selectedIndex)}
            type="button"
            className="next-btn result-btn"
          >
            See Results
            <div className="icon">
              <i className="bi bi-bar-chart"></i>
            </div>
          </button>
        </div>
      ) : null}
      </div>

      
    </div>
  );
}

export default Quiz;
