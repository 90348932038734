import React from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "../assets/bg.png";
import emailjs from "emailjs-com";

import "../assets/css/style.css";
import "../assets/css/quiz.css";
import "../assets/css/result.css";
import "../assets/css/home.css";

function Result() {
  const location = useLocation();
  const allAnswers = location.state.answers;
  const allQuestions = location.state.questions;
  const user = location.state.user;
  let percentile = 0;
  let level = "";

  allAnswers.forEach((item) => {
    if (item.trueAnswer) {
      percentile += 1;
    }
  });
  if (percentile >= 1 && percentile <= 15) {
    level = "A1";
  } else if (percentile >= 16 && percentile <= 23) {
    level = "A2";
  } else if (percentile >= 24 && percentile <= 30) {
    level = "B1";
  } else if (percentile >= 31 && percentile <= 40) {
    level = "B2";
  }
  emailjs.init("jZpcipwzR1MmKeXnD");
  const sendEmail = () => {
    emailjs.send("service_mffseyj", "template_yj1qlob", {
      to_email: "nigar@londonschool.az",
      message: `${user.name} testi tamamladı. Email: ${user.email}, Nömrə: ${user.number}, Test nəticəsi: ${level}`,
    });
  };
  sendEmail();
  return (
    <div className="app-container">
      <div className="quiz-box">
        <div className="result">
          <div className="result-box">
            <div className="result-bg">
              <span className="percentile">
                {Math.round((percentile / allQuestions.length) * 100)}%
              </span>
              <img src={Image} alt="result" />
            </div>
            <p className="result-detail">
              You answered {percentile} out of {allQuestions.length} questions
              correctly!
            </p>
            <p className="result-detail">Your English Level is: {level}</p>

            <Link to="/" className="new-quiz">
              Back To Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
